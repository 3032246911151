import styled from 'vue-styled-components'

export default styled('div')`
  .sticky {
    width: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
  }
`
